import React from 'react';
import ReactDOM from 'react-dom';
import "@fontsource/ubuntu/400.css";
import "@fontsource/ubuntu/700.css";

import './index.css';
import App from './App';

import { ChakraProvider, ColorModeScript, extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
    fonts: { heading: "Ubuntu", body: "Ubuntu" },
    config: {
        initialColorMode: 'dark',
        useSystemColorMode: true,
    },
})


ReactDOM.render(
    <React.StrictMode>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <ChakraProvider theme={theme}>
            <App />
        </ChakraProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
