import portrait from './profile_512x512.jpg';
import {Box, Center, HStack, Icon, Image, SimpleGrid, Stack, Text, Link, useBreakpointValue, useColorModeValue } from "@chakra-ui/react";
import { FaPhoneSquare, FaEnvelopeSquare, FaLinkedin, FaXingSquare, FaGithubSquare, FaStackOverflow } from 'react-icons/fa';

function SocialButton(props) {
    const mode = useBreakpointValue({ base: "icon", md: "text-icon" });

    return (
        <Link
            href={props.href} isExternal
            px={6}
            py={2}
            border="2px"
            rounded="full"
            borderColor="blue.400"
            fontWeight="700"
            _hover={{
                textColor:"white",
                bgGradient:"linear(to-r, blue.400, blue.900)"
            }}
        >
            <HStack justify={{base:"center", md:"space-between"}}>
                { (mode === "text-icon") ? <Text>{props.title}</Text> : null }
                <Icon as={props.icon} boxSize="1.4em"/>
            </HStack>
        </Link>
    );
}

function Card() {
    return (
        <Box
            bgColor={useColorModeValue('gray.100', 'gray.800')}
            boxShadow="base"
            border="base"
            rounded="base"
        >
            <Stack p={8} align="center">
                <Image
                    borderRadius="full"
                    boxSize={{base: "8em", md:"12em"}}
                    objectFit="cover"
                    src={portrait}
                    alt="Felix Maaß"
                />
                <Text fontSize={{base: "4xl", md:"5xl"}}>Felix Maaß</Text>
                <Text display={{base: "none", sm:"initial"}}>Querdenker und Problemlöser</Text>
            </Stack>
            <SimpleGrid
                p={8}
                spacing={{base: 6, md: 10}}
                borderTop="1px"
                borderColor={useColorModeValue('gray.300', 'gray.600')}
                w="100%"
                columns={2}
            >
                <SocialButton title="Telefon"         icon={FaPhoneSquare}    href="tel:00491741636716" />
                <SocialButton title="Mail"            icon={FaEnvelopeSquare} href="mailto:moin@flixma.de" />
                <SocialButton title="LinkedIn"        icon={FaLinkedin}       href="https://www.linkedin.com/in/flixma" />
                <SocialButton title="XING"            icon={FaXingSquare}     href="https://www.xing.com/profile/Felix_Maass4/cv" />
                <SocialButton title="Github"          icon={FaGithubSquare}   href="https://github.com/FlixMa" />
                <SocialButton title="StackOverflow"   icon={FaStackOverflow}  href="https://stackoverflow.com/users/3763202/flixma?tab=profile"  />
            </SimpleGrid>
        </Box>
    );
}

function App() {
  return (
      <Center w="100vw" h="100vh" bgGradient="linear(to-tr, blue.900, blue.400)" p={8}>
          <Card />
      </Center>
  );
}

export default App;
